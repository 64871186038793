html {
  scroll-behavior: smooth;
}

.link {
  text-decoration: none !important;
  transition: all 0.3s ease;
}

.link:hover {
  color: #ffae01;
}

.img-pos {
  left: 5px;
}

.timeline::before {
  width: 6px;
}

.header {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 999;
  background: rgba(0, 0, 0, 0.5);
}

.mobile-btn {
  display: none;
}

.desktop-btn {
  display: block;
}

.disabled {
  opacity: 0.9;
  cursor: not-allowed;
}
.apes_heading{
  text-align: center;
}

@media screen and (max-width: 1169px) {
  .vertical-timeline-element-icon {
    margin-left: 6px !important;
  }
}

@media screen and (min-width: 769px) {
  .mobile-btn {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  .desktop-btn {
    display: none;
  }
}

.inside-image {
  transform: translate(-50%, -50%);
  z-index: 1;
}

.glass {
  background-color: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(5px);
  box-shadow: -1px 3px 11px -4px rgba(0, 0, 0, 0.75);
}

.roadmap-heading {
  color: #fff !important;
  margin-bottom: 10px;
}

.color {
  color: #ffae01;
}

.img-container {
  width: 330px !important;
  margin: 0 20px !important;
  border-radius: 0 !important;
  position: relative;
}

.img-overlay {
  position: absolute;
  background: rgba(0, 0, 0, 0.6);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.chakra-link {
  text-decoration: none !important;
}

.chakra-link:focus {
  box-shadow: none !important;
}
